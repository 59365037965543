import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";

const Footer = (props) => {
  const { siteTitle } = props;

  return (
    <footer>
      <Navbar bg="primary">
        <Container>
          <Row>
            <Col>
              <span className="text-white-50">© {new Date().getFullYear()}
                  {` `}
                  {siteTitle}
              </span>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </footer>
  )
}

export default Footer;