import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Form} from "react-bootstrap";
import styled from "styled-components";
import { useAuth0 } from "../react-auth0-spa";
import { navigate } from "@reach/router";

const StylesOverride = styled.div`
  .navbar-dark .navbar-nav .nav-link {
    color: #808080;
  }
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
`
const Login = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const handleLogout = (e) => {
    e.preventDefault();
    const auth0Home = encodeURIComponent(process.env.GATSBY_AUTH0_HOME);
    const auth0Domain = process.env.GATSBY_AUTH0_DOMAIN;
    const auth0ClientId = process.env.GATSBY_AUTH0_CLIENTID;
    const logoutUrl = `https://${auth0Domain}/v2/logout?redirectTo=${auth0Home}&client_id=${auth0ClientId}`
    navigate(logoutUrl);
  }
  return (
    <div>
    {!isAuthenticated && (
      <button onClick={() => loginWithRedirect({})}>Log in</button>
    )}

    {isAuthenticated && user && <button onClick={handleLogout}>Log out</button>}
  </div>
  )
}

const Header = ({ siteTitle }) => (
  <header>
    <StylesOverride>
      <Navbar fixed="top" variant="dark" bg="primary" expand="md" className="py-2">
        <Navbar.Brand as={Link} to="/">{siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" activeClassName="active">Home</Nav.Link>
            <Nav.Link as={Link} to="/manage-members" activeClassName="active">Members</Nav.Link>
          </Nav>
          <Form inline>
            <Login />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </StylesOverride>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
