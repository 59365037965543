/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import "bootswatch/dist/flatly/bootstrap.min.css";
import Layout from "./src/components/layout";
import { Auth0Provider } from "./src/react-auth0-spa";

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement = ({ element }) => (
  <Auth0Provider
    domain={`${process.env.GATSBY_AUTH0_DOMAIN}`}
    client_id={`${process.env.GATSBY_AUTH0_CLIENTID}`}
    redirect_uri={`${process.env.GATSBY_AUTH0_CALLBACK}`}
  >
    {element}
  </Auth0Provider>
)